import dayjs, { type Dayjs } from 'dayjs';

export default (now: Dayjs = dayjs()): { fromDate: Dayjs; toDate: Dayjs } => {
  if (now.day() >= 5) {
    // we are on friday or saturday
    return {
      fromDate: now,
      toDate: now.day(7),
    };
  } else if (now.day() === 0) {
    // we are on a sunday
    return {
      fromDate: now,
      toDate: now,
    };
  } else {
    return {
      fromDate: now.day(5),
      toDate: now.day(7),
    };
  }
};
