import dayjs from 'dayjs';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigEventDefFragment } from '../gql/fragments/__generated/RawWidgetConfigEventDef';
import type { EventFilter } from '../gql/schema';
import { WhlDateSearchType } from '../models/WhlDateSearchType';
import { WhlLocationSearchType } from '../models/WhlLocationSearchType';
import { WhlModuleType } from '../models/WhlModuleType';

export default (
  widgetConfigRef: MaybeRefOrGetter<RawWidgetConfigFragment>
): Ref<EventFilter> => {
  const typeConfigRef = useWidgetTypeConfig(
    widgetConfigRef,
    WhlModuleType.Event
  ) as Ref<RawWidgetConfigEventDefFragment>;

  return computed((): EventFilter => {
    const widgetConfig = toValue(widgetConfigRef);

    const baseFilter: EventFilter = {};

    // client restriction
    if (widgetConfig?.client?.id > 0) {
      baseFilter.client = {
        id: { oneOf: [widgetConfig.client?.id ?? -1] },
      };

      if (widgetConfig.includeSubClients === true) {
        baseFilter.client.includeSubClients = true;
      }
    }

    const typeConfig: RawWidgetConfigEventDefFragment = toValue(typeConfigRef);
    if (typeConfig) {
      // categories
      if (
        typeConfig.searchCategories &&
        !isEmpty(typeConfig.searchCategories)
      ) {
        baseFilter.categories = {
          oneOf: mapToIds(typeConfig.searchCategories),
        };
      }

      // criteria
      if (typeConfig.searchCriteria && !isEmpty(typeConfig.searchCriteria)) {
        baseFilter.criteria = {
          oneOf: mapToIds(typeConfig.searchCriteria),
        };
      }

      // date range
      if (typeConfig.dateSearchType && typeConfig.dateSearchType > 0) {
        const nextWeekend = buildDateRangeForNextWeekend();

        switch (typeConfig.dateSearchType) {
          case WhlDateSearchType.FixedDate:
            baseFilter.fromDate = typeConfig.fixedStartDate;
            baseFilter.toDate = typeConfig.fixedEndDate;
            break;
          case WhlDateSearchType.RelativeDate:
            baseFilter.fromDate = dayjs()
              .add(parseInt(typeConfig.intervalFrom ?? '0', 10), 'days')
              .format('YYYY-MM-DD');
            baseFilter.toDate = dayjs()
              .add(parseInt(typeConfig.intervalTo ?? '7', 10), 'days')
              .format('YYYY-MM-DD');
            break;
          case WhlDateSearchType.NextWeekend:
            baseFilter.fromDate = nextWeekend.fromDate.format('YYYY-MM-DD');
            baseFilter.toDate = nextWeekend.toDate.format('YYYY-MM-DD');

            break;
        }
      }

      // start time
      if (typeConfig.startTime && !isEmpty(typeConfig.startTime)) {
        baseFilter.startTime = { from: typeConfig.startTime };
      }

      // location
      if (typeConfig.locationSearchType && typeConfig.locationSearchType >= 0) {
        switch (typeConfig.locationSearchType) {
          case WhlLocationSearchType.EventLocation:
            if (!isEmpty(typeConfig.locations)) {
              baseFilter.eventLocation = {
                id: { oneOf: mapToIds(typeConfig.locations ?? []) },
              };
            }
            break;
          case WhlLocationSearchType.AddressPoiGroup:
            if (!isEmpty(typeConfig.poiGroups)) {
              baseFilter.eventLocation = {
                group: { oneOf: mapToIds(typeConfig.poiGroups ?? []) },
              };
            }
            break;
          case WhlLocationSearchType.Location:
            if (!isEmpty(typeConfig.location)) {
              baseFilter.eventLocation = {
                location: { oneOf: mapToIds([typeConfig.location]) },
              };
            }
            break;
          case WhlLocationSearchType.Coordinates:
            if (
              typeConfig.coordinates &&
              typeConfig.coordinates.latitude &&
              typeConfig.coordinates.latitude != 0 &&
              typeConfig.coordinates.longitude &&
              typeConfig.coordinates.longitude != 0 &&
              typeConfig.radius > 0
            ) {
              baseFilter.geoFilter = {
                distanceFromPoint: {
                  point: {
                    latitude: typeConfig.coordinates?.latitude as number,
                    longitude: typeConfig.coordinates?.longitude as number,
                  },
                  maxDistance: typeConfig.radius as number,
                },
              };
            }
            break;
          case WhlLocationSearchType.ZipCode:
            if (!isEmpty(typeConfig.zipcodes)) {
              const zipCodes = (typeConfig.zipcodes ?? '').split(',');
              if (zipCodes.length > 0) {
                baseFilter.eventLocation = {
                  zipcode: { oneOf: zipCodes },
                };
              }
            }
            break;
          case WhlLocationSearchType.Region:
            baseFilter.eventLocation = {
              regions: { oneOf: mapToIds([typeConfig.region]) },
            };
            break;
        }
      }

      // event series
      if (typeConfig.series && !isEmpty(typeConfig.series)) {
        baseFilter.series = {
          oneOf: mapToIds(typeConfig.series),
        };
      }
    }

    return baseFilter;
  });
};
