export enum WhlLocationSearchType {
  None = -1,
  /** Spielstätte */
  EventLocation = 1,
  AddressPoiGroup = 2,
  ZipCode = 3,
  Coordinates = 4,
  Region = 5,
  /* Ort */
  Location = 6,
}
